
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import { mockTemplateData, ITemplate } from "@/core/data/template";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { checkPermission } from "@/core/helpers/helps";
import ComponentDeleteTemplateModal from "@/components/modals/DeleteTemplateModal.vue";
// set tutor
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default defineComponent({
  name: "MasterTemplate",
  components: { ComponentDeleteTemplateModal },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    setCurrentPageTitle(t("template"));
    setCurrentPageBreadcrumbs(("template"), [
          {title:("common.setting"), path: '/setting'},
          {title:("masterData"), path: '/setting/master-data/template'},
      ]);
    const tableData = ref<ITemplate[]>([]);
    async function fetchTableData(t) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(mockTemplateData), t)
      );
    }

    onMounted(async () => {
      // store.commit(storeTutorial.Mutations.FILTER_STEP, {
      //   name: "template-setting",
      //   bool: false,
      // } as Tutorial.localStorage);
      fetchTableData(3000).then((res) => {
        store.commit(storeTutorial.Mutations.FILTER_STEP, {
          name: "template-setting",
          bool: true,
        } as Tutorial.localStorage);
        tableData.value = res as ITemplate[];
      });
    });

    const selectedTemplate = ref<ITemplate>({
      name: "",
      id: 0,
      created_at: "",
      created_by: "",
    });
    const dialog = reactive({ edit: false, remove: false });
    function openDialog(mode, data) {
      selectedTemplate.value = data;
      dialog[mode] = true;
    }
    function closeDialog() {
      dialog.edit = false;
      dialog.remove = false;
      selectedTemplate.value = {
        name: "",
        id: 0,
        created_at: "",
        created_by: "",
      };
    }

    function handleSuccessRemove() {
      tableData.value = tableData.value.filter(
        (item) => item.id !== selectedTemplate.value?.id
      );
      closeDialog();
    }

    return {
      dialog,
      closeDialog,
      openDialog,
      handleSuccessRemove,
      selectedTemplate,
      tableData,
      checkPermission,
    };
  },
});
