
import { defineComponent, PropType, ref, toRef } from "vue";
import { ITemplate } from "@/core/data/template";

export default defineComponent({
  name: "component-delete-template-modal",
  props: {
    template: {
      type: Object as PropType<ITemplate>,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["removed", "canceled"],
  setup(props, context) {
    const isLoading = ref(false);
    const isOpen = toRef(props, "open");
    function submit() {
      context.emit("removed");
    }
    function cancel() {
      context.emit("canceled");
    }
    return {
      isOpen,
      isLoading,
      cancel,
      submit,
    };
  },
});
