export interface ITemplate {
  id: number;
  name: string;
  created_at: string;
  created_by: string;
}

export const mockTemplateData: Array<ITemplate> = [
  {
    id: 1,
    name: "Template Program Marketing Rumah Sakit Bandung",
    created_at: "01 Jan 2021",
    created_by: "Bella Dwi Jayanti",
  },
  {
    id: 2,
    name: "Template Pembangunan Perumahan Jakarta Selatan",
    created_at: "09 Jul 2020",
    created_by: "Haya Zhafira",
  },
  {
    id: 3,
    name: "Template pembangunan Rumah Sakit Jakarta Timur",
    created_at: "22 Jul 2020",
    created_by: "Ari Vio Sarjono",
  },
  {
    id: 4,
    name: "Template Pembangunan perkantoran Bandung",
    created_at: "28 Des 2020",
    created_by: "Naufal Rasyad",
  },
  {
    id: 5,
    name: "Template pembangunan Rumah Sakit Bandung",
    created_at: "22 Jan 2020",
    created_by: "Fajar Rizqy",
  },
];
